import {MonorailTracker as MonorailTrackerBase} from '../../../common/analytics';
import {LoginWithShopSdkUserAction} from '../../../common/analytics/types';
import {
  ANALYTICS_CONTEXT_TO_FLOW_MAP,
  DefaultComponentAnalyticsContext as AnalyticsContext,
} from '../../../constants/loginDefault';

export default class DefaultComponentMonorailTracker extends MonorailTrackerBase {
  private _emailEnteredUserActionTracked = false;

  /**
   * @param {object} params The constructor params.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   * @param {AnalyticsContext} params.analyticsContext The context of the analytics event.
   * This is used to dismabinuate between different flows that use the same default component.
   * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up")
   * @param {number} [params.shopId] The numeric id of the shop.
   * @param {number} [params.shopPermanentDomain] The 'myshopify' domain of the shop.
   * @param {string} [params.checkoutVersion] A checkout version such as "classic" or "shop_pay_external"
   * @param {string} [params.checkoutToken] A checkout token if present.
   */
  constructor({
    elementName,
    analyticsTraceId,
    analyticsContext = AnalyticsContext.Default,
    flowVersion = 'unspecified',
    checkoutVersion,
    checkoutToken,
    shopId,
    shopPermanentDomain,
  }: {
    elementName: string;
    analyticsTraceId?: string;
    analyticsContext?: AnalyticsContext;
    flowVersion?: string;
    checkoutVersion?: string;
    checkoutToken?: string;
    shopId?: number;
    shopPermanentDomain?: string;
  }) {
    super({
      elementName,
      analyticsTraceId,
      flow: ANALYTICS_CONTEXT_TO_FLOW_MAP[analyticsContext],
      flowVersion,
      checkoutVersion,
      checkoutToken,
      shopId,
      shopPermanentDomain,
    });
  }

  /**
   * Fired when the shop-login-default button is clicked.
   */
  trackLoginDefaultButtonClicked() {
    this.trackShopPayLoginWithShopSdkUserAction({
      userAction: LoginWithShopSdkUserAction.ContinueWithShopClicked,
    });
  }

  trackEmailEnteredAction() {
    if (this._emailEnteredUserActionTracked) {
      return;
    }
    this._emailEnteredUserActionTracked = true;
    this.trackShopPayLoginWithShopSdkUserAction({
      userAction: LoginWithShopSdkUserAction.EmailEntered,
    });
  }
}
