import {DefaultComponentAnalyticsContext} from 'constants/loginDefault';

import {BugsnagEventType} from 'common/bugsnag/types';
import {checkEligibleForCompactLayout} from 'common/utils/isCompactLayout';

import config from '../../config';
import Bugsnag from '../bugsnag';
import {BUGSNAG_APP_ID, APP_VERSION} from '../constants';

/**
 * @returns {boolean} Whether the browser supports Web Components.
 */
export function isBrowserSupported(): boolean {
  return Boolean(window.customElements);
}

/**
 * Define a custom element (web component) if the browser supports custom elements and if it has not been
 * previously defined.
 * @param {string} name Name for the new custom element. This will be the distinct HTML tag in kebab-case
 * by which the element will be created. Note that custom element names must contain a hyphen.
 * @param {object} component Constructor for the new custom element.
 */
export function defineCustomElement(
  name: string,
  component: CustomElementConstructor,
) {
  // Return early if the browser doesn't support Web Components.
  if (!window.customElements) return;

  if (!customElements.get(name)) {
    customElements.define(name, component);
  }
}

/**
 * Adds the URLs of all shop-js bundles on the page to the Bugsnag event metadata.
 * This does not tell us which bundle the error came from, but it's still valuable
 * data to have.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addBundleMetadata(event: BugsnagEventType): void {
  const shopJsUrls = (
    Array.from(
      document.querySelectorAll('script[src*="/shop-js/"]'),
    ) as HTMLScriptElement[]
  ).map((scriptTag) => scriptTag.src);
  event.addMetadata('shopJsUrls', shopJsUrls);
}

/**
 * Adds metadata to the Bugsnag event to indicate whether the window.Shopify.featureAssets
 * object exists. We want to be conscious of the payload we send to Bugsnag, so sending the
 * entire object is not ideal. This is a compromise.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addFeatureAssetsMetadata(event: BugsnagEventType): void {
  const featureAssets: Record<string, any[]> | undefined =
    window.Shopify?.featureAssets?.['shop-js'];
  const featureAssetsNonEmpty = Boolean(
    featureAssets && Object.keys(featureAssets).length > 0,
  );
  event.addMetadata('shopJsFeatureAssetsExist', featureAssetsNonEmpty);
}

/**
 * Adds metadata to the Bugsnag event to indicate whether the compact UX is being used.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addCompactUXMetadata(event: BugsnagEventType): void {
  const compactShopLoginButton = Array.from(
    document.querySelectorAll('shop-login-button[compact]'),
  );
  compactShopLoginButton.filter((el) =>
    checkEligibleForCompactLayout(
      el.getAttribute('analytics-context') as DefaultComponentAnalyticsContext,
    ),
  );
  event.addMetadata('compactUX', compactShopLoginButton.length > 0);
}

/**
 * Adds the error class and error message as a grouping hash to the Bugsnag event.
 * @param {BugsnagEventType} event The Bugsnag Event to add metadata to.
 */
function addGroupingHash(event: BugsnagEventType): void {
  const groupingHash = `${event.exceptions[0].errorClass}:${event.exceptions[0].errorMessage}`;
  event.groupingHash = groupingHash;
}

/**
 * Util for starting Bugsnag with the correct configuration. Deliberately put outside /common/bugsnag to
 * keep that as a pure Bugsnag client implementation.
 * @param {object} metadata Diagnostic metadata that you want to send with all captured events.
 */
export function startBugsnag(metadata = {}) {
  Bugsnag.start({
    apiKey: config.bugsnagApiKey,
    appId: BUGSNAG_APP_ID,
    appVersion: APP_VERSION,
    metadata,
    onError: [
      addBundleMetadata,
      addFeatureAssetsMetadata,
      addCompactUXMetadata,
      addGroupingHash,
    ],
  });
}

/**
 * Adds an init function to the window.
 * @param {string} signInWithShopKey The key to be added to window.Shopify.SignInWithShop
 * @param {Function} initFunction The corresponding init function
 */
export function defineInitFunction(
  signInWithShopKey: string,
  initFunction: (arg0: any) => any,
) {
  if (!window.Shopify) {
    window.Shopify = {};
  }

  if (!window.Shopify.SignInWithShop) {
    window.Shopify.SignInWithShop = {};
  }

  window.Shopify.SignInWithShop[signInWithShopKey] = initFunction;
}
